import {
  ApolloClient,
  ApolloProvider,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { gqlUrl } from "./constants";

type Props = {
  children: React.ReactNode;
};

export function AuthorizingApolloProvider(props: Props) {
  const client = React.useRef<ApolloClient<NormalizedCacheObject>>();
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = new HttpLink({
    uri: gqlUrl,
  });

  const authLink = setContext(
    async (_: GraphQLRequest, { headers, ...rest }: Request) => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        console.log(error);
      }

      if (!token) return { headers, ...rest };

      return {
        ...rest,
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    }
  );

  if (!client.current) {
    client.current = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  return (
    <ApolloProvider client={client.current}>{props.children}</ApolloProvider>
  );
}
