import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import { AuthorizingApolloProvider } from "./AuthorizingApolloProvider";
import { BrowserRouter } from "react-router-dom";

const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="jump-be.eu.auth0.com"
        audience="https://okseri.fi"
        clientId="6etng8dfbjgE0HXI1PL1WlNLd2Vpj7JA"
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
      >
        <AuthorizingApolloProvider>
          <App />
        </AuthorizingApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);
